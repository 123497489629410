'use strict';

import $ from 'jquery';
import 'slick-carousel';
import enquire from 'enquire.js';

export default class OurPeopleItem {
	constructor() {
		let $peopleTesti = $('.our-people-testi'),
			$peopleQuotes = $('.our-people-testi__quotes', $peopleTesti),
			$quotes = $('.quotes', $peopleQuotes),
			$author = $('.author', $peopleQuotes),
			$people = $('.our-people-testi__people', $peopleTesti),
			$peopleScroll = $('.our-people-testi__scroll', $people),
			$peopleItem = $('li', $people);
			// $topSlider = $('.our-people-testi__topslider', $peopleTesti),
			// $btmSlider = $('.our-people-testi__btmslider', $peopleTesti);

		$peopleItem.each(function() {
			let $this = $(this),
				$authorItem = $this.find('.author'),
				$quotesItem = $this.find('.quotes');

			if ($this.index() === 0) {
				$quotes.text($quotesItem.text());
				$author.text($authorItem.text());
			}

			$this.on('mouseenter', function(e) {
				$quotes.text($quotesItem.text());
				$author.text($authorItem.text());
			});
		});

		enquire.register("screen and (max-width:1023px)", {
			match() {
				if ($peopleItem.length === 4) {
					$('ul', $peopleScroll).css('min-width', '350px');
				} else if ($peopleItem.length === 5) {
					$('ul', $peopleScroll).css('min-width', '440px');
				} else if ($peopleItem.length === 6) {
					$('ul', $peopleScroll).css('min-width', '530px');
				} else if ($peopleItem.length === 7) {
					$('ul', $peopleScroll).css('min-width', '630px');
				} else if ($peopleItem.length === 8) {
					$('ul', $peopleScroll).css('min-width', '720px');
				} else if ($peopleItem.length === 9) {
					$('ul', $peopleScroll).css('min-width', '800px');
				}
			},
			unmatch() {
				$('ul', $peopleScroll).removeAttr('style');
			}
		});

		// $topSlider.slick({
		// 	slidesToShow: 1,
		// 	slidesToScroll: 1,
		// 	arrows: false,
		// 	fade: true,
		// 	asNavFor: '.our-people-testi__btmslider'
		// });

		// $btmSlider.slick({
		// 	slidesToShow: 8,
		// 	slidesToScroll: 1,
		// 	accessibility: false,
		// 	asNavFor: '.our-people-testi__topslider',
		// 	dots: false,
		// 	arrows: false,
		// 	centerMode: false,
		// 	focusOnSelect: true,
		// 	infinite: false,
		// 	responsive: [
		// 		{
		// 			breakpoint: 850,
		// 			settings: {
		// 				slidesToShow: 3,
		// 				variableWidth: true,
		// 				infinite: true
		// 			}
		// 		}
		// 	  ]
		// });
	}
}
