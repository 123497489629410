'use strict';

import $ from 'jquery';

export default class ResourcesBanner {
	constructor() {
		let $resourcesBanner = $('.resources-banner'),
			$select = $('select', $resourcesBanner),
			$dropdown = $('.dropdown-copy', $resourcesBanner),
			$selectDropdown = $('.select-dropdown', $resourcesBanner);

		let dropdownLength = $selectDropdown.length;

		if (dropdownLength === 3) {
			$selectDropdown.addClass('three-dropdown');
		} else {
			$selectDropdown.addClass('four-dropdown');
		}

		$select.map((i,ele) => {
			let $this = $(ele),
				$option = $('option', $this);

			let firstOption = $('option', $this).first().text(),
				firstValue = $('option', $this).first().val(),
				_clone = `<div class="dropdown-copy"><button type="button" value="${firstValue}">${firstOption}</button><ul>`;

			$option.each(function() {
				let text = $(this).text(),
					value = '';

				if($(this).attr('value')){
					value = $(this).attr('value');
				}

				let _optionClone = `<li><button type="button" value="${value}"><span>${text}</span></button></li>`;
				_clone += _optionClone;
			});

			_clone = `${_clone}</ul></div>`;

			$this.after(_clone);
		});

		$selectDropdown.map((i, ele) => {
			let $this = $(ele),
				$clone = $('.dropdown-copy', $this),
				$dropdown = $('ul', $this),
				$button = $('> button', $clone);

			$this.on('click', (e) => {
				e.stopPropagation();
				$('ul', $selectDropdown).not($dropdown).hide();
				this.toggleDropdown($dropdown);

				if ($dropdown.parent().hasClass('rotate')) {
					$dropdown.parent().removeClass('rotate');
				} else {
					$dropdown.parent().addClass('rotate');
				}

			});

			let $option = $('li button', $dropdown);

			$option.map((j, ele) => {
				let $this2 = $(ele);

				$this2.on('click', () => {
					let value = $this2.val(),
						text = $this2.text();

					$button.text(text);
					$button.val(value);
				});
			});
		});

		$('.resourcesSearchBox .searchBox').on("keypress", function(e) {
			let keycode = e.keyCode ? e.keyCode : e.which;
			if (keycode == "13") {
				e.preventDefault();
				$('.resourcesSearchBtn .resourcesSearch').trigger("click");
			}
		});

		$('.resourcesSearchBtn .resourcesSearch').on('click', function(e) {
			e.preventDefault();
			let searchURL = window.location.pathname,
				$inputText = $('.resourcesSearchBox input', $('.resources-banner__search')),
				$inputTextVal = $inputText.val(),
				$firstDD = $('.tag1 > .dropdown-copy > button', $('.resources-banner__search')),
				$firstDDText = $firstDD.text(),
				$secondDD = $('.tag2 > .dropdown-copy > button', $('.resources-banner__search')),
				$secondDDText = $secondDD.text(),
				$thirdDD = $('.tag3 > .dropdown-copy > button', $('.resources-banner__search')),
				$thirdDDText = $thirdDD.text(),
				$sortFilter = $('.sort-filter .sort-filter__selected').text();

			if ($('.select-dropdown', $('.resources-banner__search')).hasClass('four-dropdown')) {
				let $fourthDD = $('.tag4 > .dropdown-copy > button', $('.resources-banner__search')),
					$fourthDDText = $fourthDD.text();

				window.location.href = getQuery($inputTextVal, searchURL, $sortFilter, $firstDDText, $secondDDText, $thirdDDText, $fourthDDText);
			} else {
				window.location.href = getQuery($inputTextVal, searchURL, $sortFilter, $firstDDText, $secondDDText, $thirdDDText);
			}
		});

		if ((window.location.search).length) {
			if (document.getElementById("tag1")) {
				let tag1DD = document.getElementById("tag1"),
					tag1DDValue = tag1DD.options[tag1DD.selectedIndex].text;

				$('.tag1 > .dropdown-copy > button').text(tag1DDValue);
			}

			if (document.getElementById("tag2")) {
				let tag2DD = document.getElementById("tag2"),
					tag2DDValue = tag2DD.options[tag2DD.selectedIndex].text;

				$('.tag2 > .dropdown-copy > button').text(tag2DDValue);
			}

			if (document.getElementById("tag3")) {
				let tag3DD = document.getElementById("tag3"),
					tag3DDValue = tag3DD.options[tag3DD.selectedIndex].text;

				$('.tag3 > .dropdown-copy > button').text(tag3DDValue);
			}

			// let tag1DD = document.getElementById("tag1"),
			// 	tag1DDValue = tag1DD.options[tag1DD.selectedIndex].text,
			// 	tag2DD = document.getElementById("tag2"),
			// 	tag2DDValue = tag2DD.options[tag2DD.selectedIndex].text,
			// 	tag3DD = document.getElementById("tag3"),
			// 	tag3DDValue = tag3DD.options[tag3DD.selectedIndex].text;

			if ($('.select-dropdown', $('.resources-banner__search')).hasClass('four-dropdown')) {
				let tag4DD = document.getElementById("tag4"),
					tag4DDValue = tag4DD.options[tag4DD.selectedIndex].text;

				$('.tag4 > .dropdown-copy > button').text(tag4DDValue);
			}
		}

		$(window).on('click', () => {
			$('ul', $selectDropdown).hide();
		});

		function getQuery(searchValue, searchURL, sortFilter, firstDDText, secondDDText, thirdDDText, fourthDDText) {
			return searchURL + '?q=' + encodeURIComponent(searchValue) + '&sort=' + sortFilter + '&tag1=' + firstDDText + '&tag2=' + secondDDText + '&tag3=' + thirdDDText + '&tag4=' + fourthDDText;
		}

		$('html').on('click', function(e) {
			$('.resources-banner__search .dropdown-copy').each(function(e) {
				if ($(this).hasClass('rotate')) {
					$(this).removeClass('rotate');
				}
			});
		});
	}

	toggleDropdown($object){
		$object.toggle();
	}
}
