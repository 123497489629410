'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import 'jquery-match-height';

export default class LandingWidget {
	constructor() {
		let $landing = $('.landing-widget'),
			$landingItem = $('.landing-item', $landing),
			$landingText = $('.landing-item__text', $landingItem);

		enquire.register("screen and (min-width:768px)", {
			match() {
				$landingText.matchHeight();
			},
			unmatch() {
				$landingText.matchHeight({
					remove: 'true'
				});
			}
		});
	}
}
