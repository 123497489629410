'use strict';

import $ from 'jquery';
import 'jquery-match-height';

export default class LatestResources {
	constructor() {
		let $latestResources = $('.latest-resources'),
			$latestResourcesItem = $('.latest-resources__item', $latestResources),
			$nonFeatured = $('.latest-resources__item.non-featured', $latestResources),
			$latestResourcesContent = $('.latest-resources__content', $nonFeatured),
			$latestResourcesImage = $('.latest-resources__image', $nonFeatured);

		$latestResourcesContent.matchHeight();
		$latestResourcesItem.matchHeight();
	}
}
