'use strict';

import $ from 'jquery';
import 'jquery-match-height';
import enquire from 'enquire.js';

export default class CaseRegistryWidget {
	constructor() {
		let $caseRegistry = $('.case-registry'),
			$caseRegistryItem = $('.case-registry__item', $caseRegistry),
			$caseRegistryListing = $('.case-registry__listing', $caseRegistry),
			$imageCardText = $('.image-card__text', $caseRegistryItem);

		$imageCardText.matchHeight();

		enquire.register("screen and (max-width:767px)", {
			match() {
				if ($caseRegistryItem.length === 1) {
					$caseRegistryListing.addClass("one-item");
				} else if ($caseRegistryItem.length === 2) {
					$caseRegistryListing.addClass("two-items");
				} else if ($caseRegistryItem.length === 3) {
					$caseRegistryListing.addClass("three-items");
				} else if ($caseRegistryItem.length === 4) {
					$caseRegistryListing.addClass("four-items");
				}
			},
			unmatch() {
				$caseRegistryListing.removeClass("one-item");
				$caseRegistryListing.removeClass("two-items");
				$caseRegistryListing.removeClass("three-items");
				$caseRegistryListing.removeClass("four-items");
			}
		});

		enquire.register("screen and (min-width:768px) and (max-width: 1023px)", {
			match() {
				if ($caseRegistryItem.length === 5) {
					$caseRegistryListing.addClass("five-items");
				} else {
					$caseRegistryListing.removeClass("five-items");
				}
			},
			unmatch() {
				$caseRegistryListing.removeClass("five-items");
			}
		});
	}
}
