'use strict';

import $ from 'jquery';
import 'slick-carousel';
import 'jquery-match-height';

export default class BannerCarousel {
	constructor() {
		let $bannerCarousel = $('.banner-carousel'),
			$bannerItem = $('.banner-carousel__item', $bannerCarousel),
			$bannerText = $('.banner-carousel__text', $bannerCarousel),
			$bannerImage = $('.banner-carousel__image', $bannerCarousel);

		$bannerCarousel.slick({
			speed: 700,
			swipe: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: true,
			prevArrow: '<button class="slick-prev carousel-btn carousel-btn__prev" type="button"><i class="icon-chevron-left"></i></button>',
			nextArrow: '<button class="slick-next carousel-btn carousel-btn__next" type="button"><i class="icon-chevron-right"></i></button>',
			responsive: [
				{
					breakpoint: 1023,
					settings: {
						adaptiveHeight: true,
						speed: 1000,
						fade: true
					}
				}
			]
		});

		// $bannerCarousel.on('init', function(event, slick){
		// 	let $slickDots = $('.slick-dots', $bannerCarousel);
		// 	let dotsOffset = $('.banner-carousel .slick-active .banner-carousel__image').height() + 20;
		// 	$slickDots.css('bottom', dotsOffset);
		// });

		let timer;

		$(window).on('load resize.bannerCarousel', function () {
			let $carouselBtn = $('.carousel-btn', $bannerCarousel),
				$slickDots = $('.slick-dots', $bannerCarousel);

			if ($(window).width() < 1024) {
				clearTimeout(timer);

				timer = setTimeout(function () {
					// Remove matchheight
					$bannerItem.matchHeight({
						remove: 'true'
					});

					// Reposition the carousel buttons
					let bannerItemHeight = $bannerItem.outerHeight(),
						bannerImageHeight = $bannerImage.height(),
						carouselTop = (bannerItemHeight - bannerImageHeight) / 2;

					$carouselBtn.css("top", carouselTop);

					$bannerCarousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
						let $nextSlide = $(slick.$slides.get(nextSlide)),
							bannerItemHeight = $('.banner-carousel__item', $nextSlide).outerHeight(),
							bannerImageHeight = $('.banner-carousel__image', $nextSlide).height(),
							carouselTop = (bannerItemHeight - bannerImageHeight) / 2;

						$carouselBtn.css("top", carouselTop);
					});

					// Reposition the dots
					let dotsOffset = $('.banner-carousel .slick-active .banner-carousel__image').height() + 20;

					$slickDots.css('bottom', dotsOffset);
				}, 200);
			} else {
				clearTimeout(timer);

				timer = setTimeout(function () {
					let bannerTextHeight = $bannerText.height(),
						btnHeight = bannerTextHeight + 95 + 28,
						bannerItemWidth = $bannerItem.width(),
						containerWidth = $('.container', $bannerItem).width(),
						btnWidth = ((bannerItemWidth - containerWidth) / 2),
						$slickDots = $('.slick-dots', $bannerCarousel);

					// Reposition dots
					$slickDots.css("top", (btnHeight) + "px");
					$slickDots.css("left", (btnWidth) + "px");

					$bannerCarousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
						let $nextSlide = $(slick.$slides.get(nextSlide));

						bannerTextHeight = $nextSlide.find('.banner-carousel__text').height();
						btnHeight = bannerTextHeight + 95 + 28;

						$slickDots.css("top", (btnHeight) + "px");
					});
				}, 200);
			}
		}).trigger('resize.bannerCarousel');
	}
}
