'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import 'jquery-match-height';

export default class RelatedListingItem {
	constructor() {
		let $relatedItem = $('.related-listing'),
			$relatedListing = $('.related-listing-item', $relatedItem),
			$relatedText = $('.related-listing-item__text', $relatedListing);

		enquire.register("screen and (min-width:768px)", {
			match() {
				$relatedText.matchHeight();
			},
			unmatch() {
				$relatedText.matchHeight({
					remove: 'true'
				});
			}
		});
	}
}
