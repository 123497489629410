'use strict';

import $ from 'jquery';
import 'jquery-match-height';
import enquire from 'enquire.js';

export default class PublicationsItem {
	constructor() {
		let $publications = $('.publications'),
			$publicationsItem = $('.publications-item', $publications),
			$publicationsText = $('.publications-item__text', $publications),
			$publicationsTitle = $('.title', $publicationsText);

		let resizeTabs;

		$publicationsItem.matchHeight();
		$publicationsTitle.matchHeight();

		resizeTabs = function() {
			$publicationsItem.matchHeight._update();
			$publicationsTitle.matchHeight._update();
		}

		window.addEventListener('resize', resizeTabs);
	}
}
