'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import 'jquery-match-height';

export default class MainNav {
	constructor() {
		let $siteHeader = $('.site-header'),
			$nav = $('.nav', $siteHeader),
			$navMegamenu = $('.nav-megamenu', $nav),
			$navLvl1 = $('.nav__lvl1', $nav),
			$navLvl2 = $('.nav__lvl2', $nav),
			$navMegamenuListing = $('.nav-megamenu__listing', $navLvl2),
			$navContainer = $('.container', $nav);

		let resizeMargin, resizeHeight;

		enquire.register("screen and (min-width:1024px)", {
			match() {
				$('.scrollbar-inner').scrollbar();

				setTimeout(function() {
					let $headerWidth = $('.site-header__top', $siteHeader).width(),
						$containerWidth = $('.site-header__top .container', $siteHeader).width(),
						$halfWidth = (($headerWidth - $containerWidth) / 2),
						$navHeight = $nav.height();

					$('.nav .nav-megamenu').each(function(e) {
						let $this = $(this);

						$this.css("top", $navHeight + 'px');

						if ($this.hasClass('scroll-wrapper')) {
							$this.css("margin-left", -$halfWidth);
						} else {
							if (!$this.parent('.nav-megamenu').hasClass('scroll-wrapper')) {
								$this.css("margin-left", -$halfWidth);
							}
						}
					});
				}, 200);

				resizeMargin = function() {
					setTimeout(function() {
						let $headerWidth = $('.site-header__top', $siteHeader).width(),
							$containerWidth = $('.site-header__top .container', $siteHeader).width(),
							$halfWidth = (($headerWidth - $containerWidth) / 2),
							$navHeight = $nav.height();
				
						$('.nav .nav-megamenu').each(function(e) {
							let $this = $(this);
	
							$this.css("top", $navHeight + 'px');
	
							if ($this.hasClass('scroll-wrapper')) {
								$this.css("margin-left", -$halfWidth);
							} else {
								if (!$this.parent('.nav-megamenu').hasClass('scroll-wrapper')) {
									$this.css("margin-left", -$halfWidth);
								}
							}
						});
					}, 200);
				};
				window.addEventListener('resize', resizeMargin);
			},
			unmatch() {
				window.removeEventListener('resize', resizeMargin);
				$('.scrollbar-inner').scrollbar('destroy');
			}
		});

		enquire.register("screen and (min-width:1280px)", {
			match() {
				resizeHeight = function() {
					setTimeout(function() {
						let $navLvl1Height = $navLvl1.height();

						if ($navLvl1Height > 115) {
							$('> li', $navLvl1).addClass('height-overshot');
							let $liHeight = 0;

							$('.height-overshot', $navLvl1).each(function(e) {
								let $height = $(this).height();

								if ($height > $liHeight) {
									$liHeight = $height;
								}
							});

							if ($liHeight > 93) {
								$('> li', $navLvl1).addClass('height-threeline');
							} else {
								$('> li', $navLvl1).removeClass('height-threeline');
							}
						} else {
							$('> li', $navLvl1).removeClass('height-overshot');
							$('> li', $navLvl1).removeClass('height-threeline');
						}
					}, 200);
				}
				
				window.addEventListener('load', resizeHeight);
				window.addEventListener('resize', resizeHeight);
			},
			unmatch() {
				window.removeEventListener('resize', resizeHeight);
				$('.scrollbar-inner').scrollbar('destroy');
				$('li', $navLvl1).removeClass('height-overshot');
				$('li', $navLvl1).removeClass('height-threeline');
			}
		});
	}
}
