'use strict';

import $ from 'jquery';
import 'jquery-match-height';

export default class VideoGalleryItem {
	constructor() {
		let $videoGallery = $('.video-gallery'),
			$videoGalleryItem = $('.video-gallery-item', $videoGallery);

		$videoGalleryItem.matchHeight();
	}
}
