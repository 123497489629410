'use strict';

import $ from 'jquery';
import queryString from 'query-string';

export default class ListingSearch {
	constructor() {
		let $listingSearch = $('.listing-search'),
			$input = $('.listing-search-field__input', $listingSearch),
			$searchBtn = $('.listing-search-field__submit', $listingSearch),
			$param = $('.listing-table-pagination .active a').attr('href');

		$input.on('keypress', e => {
			let keycode = e.keyCode ? e.keyCode : e.which;
			if (keycode == "13") {
				e.preventDefault();
				
				$searchBtn.trigger("click");
			}
		});

		$searchBtn.on('click', function(e) {
			e.preventDefault();
			let searchValue = $input.val(),
				searchURL = window.location.pathname,
				$filterYear = $('.year-filter .year-filter__selected').text();

				window.location.href = getQuery(searchValue, searchURL, $filterYear);

		});


		function getQuery(searchValue, searchURL, filterYear) {
			return searchURL + '?q=' + encodeURIComponent(searchValue) + '&year=' + filterYear + '&page=1';
		}

		// if ((window.location.search).length) {
		// 	let sPageURL = decodeURIComponent(window.location.search.substring(1)),
		// 		sURLVariables = sPageURL.split('&'),
		// 		sParameterName,
		// 		i;

		// 	for (i = 0; i < sURLVariables.length; i++) {
		// 		sParameterName = sURLVariables[i].split('=');

		// 		if (sParameterName[0] === "q") {
		// 			$('#baseSearch').val(sParameterName[1]);
		// 		} 
		// 	}
		// }
		
	}

}

