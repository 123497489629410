'use strict';

import $ from 'jquery';
import 'slick-carousel';
import enquire from 'enquire.js';
import 'jquery-match-height';

export default class RelatedPagesWidget {
	constructor() {
		let $relatedPages = $('.related-pages'),
			$relatedPagesCarousel = $('.related-pages__list', $relatedPages),
			$relatedPagesItem = $('.related-pages-item', $relatedPages),
			$relatedPagesText = $('.related-pages-item__text', $relatedPagesItem),
			$relatedCarousel = $('.related-pages__carousel', $relatedPages),
			carouselCount = $relatedCarousel.data('count'),
			$carouselArrow = $('.carousel-topBtn', $relatedCarousel);

		$relatedPagesItem.matchHeight();

		// if ($(window).width() < 1024) { // on mobile
		// 	if (carouselCount < 2) {
		// 		$carouselArrow.addClass('display-none');
		// 	} else {
		// 		$carouselArrow.removeClass('display-none');
		// 	}
		// } else {
		// 	if (carouselCount < 3) {
		// 		$carouselArrow.addClass('display-none');
		// 	} else {
		// 		$carouselArrow.removeClass('display-none');
		// 	}

		// 	if (carouselCount === 1) {
		// 		$('.related-pages__list .slick-track').addClass('slick-count-one');
		// 	}
		// }

		// let resetCarouselArrows;

		// resetCarouselArrows = function() {
		// 	if ($(window).width() < 1024) {
		// 		if (carouselCount < 2) {
		// 			$carouselArrow.addClass('display-none');
		// 		} else {
		// 			$carouselArrow.removeClass('display-none');
		// 		}
		// 	} else {
		// 		if (carouselCount < 3) {
		// 			$carouselArrow.addClass('display-none');
		// 		} else {
		// 			$carouselArrow.removeClass('display-none');
		// 		}
		// 	}
		// }

		// window.addEventListener('resize', resetCarouselArrows);

		// if ( $(window).width() > 1023 && (carouselCount%2) != 0 ) {
		// 	$relatedPagesCarousel.addClass('carousel-clone');
		// 	$relatedPagesCarousel.append('<div class="related-pages-item carousel-clone-div"></div>');
		// } else {
		// 	$relatedPagesCarousel.remove('.carousel-clone-div');
		// }

		$relatedPagesCarousel.slick({
			speed: 700,
			dots: false,
			infinite: true,
			slidesToShow: 2,
			slidesToScroll: 2,
			prevArrow: '<a class="carousel-topBtn carousel-arrow-prev"><i class="icon-chevron-left"></i></a>',
			nextArrow: '<a class="carousel-topBtn carousel-arrow-next"><i class="icon-chevron-right"></i></a>',
			appendArrows: '.related-pages__carousel',
			responsive: [
				{
					breakpoint: 1023,
					settings: {
						adaptiveHeight: true,
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
		let beforePrint = function () {
			// console.log('Functionality to run before printing.');
			$relatedPagesCarousel.slick('unslick');
		};
		let afterPrint = function () {
			// console.log('Functionality to run after printing');
			$relatedPagesCarousel.slick({
				speed: 700,
				dots: false,
				infinite: true,
				slidesToShow: 2,
				slidesToScroll: 2,
				prevArrow: '<a class="carousel-topBtn carousel-arrow-prev"><i class="icon-chevron-left"></i></a>',
				nextArrow: '<a class="carousel-topBtn carousel-arrow-next"><i class="icon-chevron-right"></i></a>',
				appendArrows: '.related-pages__carousel',
				responsive: [{
					breakpoint: 1023,
					settings: {
					adaptiveHeight: true,
					slidesToShow: 1,
					slidesToScroll: 1
					}
				}]
			});
		};

		if (window.matchMedia) {
			let mediaQueryList = window.matchMedia('print');
			mediaQueryList.addListener(function (mql) {
				if (mql.matches) {
					beforePrint();
				} else {
					afterPrint();
				}
			});
		}

		if(!isChrome){
			window.onbeforeprint = beforePrint;
			window.onafterprint = afterPrint;
		}

		if (carouselCount === 1) {
			$('.slick-track', $relatedPagesCarousel).css('margin-left', 0);
		}

		// enquire.register("screen and (min-width: 1024px)", {
		// 	match() {
		// 		$('.slick-active .related-pages-item__text').matchHeight();

		// 		if (!$relatedPagesCarousel.hasClass('carousel-clone') && (carouselCount%2) != 0 ) {
		// 			$relatedPagesCarousel.addClass('carousel-clone');
		// 			$relatedPagesCarousel.slick('unslick');
		// 			$relatedPagesCarousel.append('<div class="related-pages-item carousel-clone-div"></div>');

		// 			$relatedPagesCarousel.slick({
		// 				speed: 700,
		// 				dots: false,
		// 				infinite: true,
		// 				slidesToShow: 2,
		// 				slidesToScroll: 2,
		// 				prevArrow: '.carousel-arrow-prev',
		// 				nextArrow: '.carousel-arrow-next',
		// 				responsive: [
		// 					{
		// 						breakpoint: 1023,
		// 						settings: {
		// 							adaptiveHeight: true,
		// 							slidesToShow: 1,
		// 							slidesToScroll: 1
		// 						}
		// 					}
		// 				]
		// 			});
		// 		}

		// 		$relatedPagesCarousel.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		// 			$('.related-pages-item__text').matchHeight();
		// 		});
		// 	},
		// 	unmatch() {
		// 		$('.related-pages-item__text').matchHeight({
		// 			remove: 'true'
		// 		});

		// 		$relatedPagesCarousel.slick('unslick');
		// 		$relatedPagesCarousel.removeClass('carousel-clone');
		// 		$( ".carousel-clone-div" ).remove();
		// 	}
		// });

		// enquire.register("screen and (max-width: 1023px)", {
		// 	match() {
		// 		$('.related-pages-item__text').matchHeight({
		// 			remove: 'true'
		// 		});

		// 		$( ".carousel-clone-div" ).remove();

		// 		if (!$relatedPagesCarousel.hasClass('slick-initialized')) {
		// 			$relatedPagesCarousel.slick({
		// 				speed: 700,
		// 				dots: false,
		// 				infinite: true,
		// 				slidesToShow: 2,
		// 				slidesToScroll: 2,
		// 				prevArrow: '.carousel-arrow-prev',
		// 				nextArrow: '.carousel-arrow-next',
		// 				responsive: [
		// 					{
		// 						breakpoint: 1023,
		// 						settings: {
		// 							adaptiveHeight: true,
		// 							slidesToShow: 1,
		// 							slidesToScroll: 1
		// 						}
		// 					}
		// 				]
		// 			});
		// 		}

		// 		$relatedPagesCarousel.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		// 			$('.related-pages-item__text').matchHeight({
		// 				remove: 'true'
		// 			});
		// 		});
		// 	},
		// 	unmatch() {
		// 		console.log("");
		// 	}
		// });
	}
}
