'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import 'jquery-match-height';

export default class CareersBanner {
	constructor() {
		let $careersBanner = $('.careers-banner'),
			$careersBannerItem = $('.careers-banner__item', $careersBanner),
			$careersBannerImage = $('.careers-banner__image', $careersBanner);

		enquire.register("screen and (min-width:1024px)", {
			match() {
				$careersBannerImage.matchHeight({
					target: $careersBannerItem
				});
			},
			unmatch() {
				$careersBannerImage.matchHeight({
					remove: 'true'
				});
			}
		});
	}
}
