'use strict';

import $ from 'jquery';

export default class ShowSortFilter {
	constructor() {
		let $showSort = $('.show-sort-filter'),
			$searchResult = $('.results', $showSort),
			$sortFilter = $('.sort-filter', $showSort),
			$selected = $('.sort-filter__selected', $sortFilter),
			$options = $('.options', $sortFilter),
			pageSizeItems = [];

		$options.find('li').each(function() {
			pageSizeItems.push($(this).find('a').text());
		});

		let pageSizeParam = this.qs('sort');
		for (let i = 0; i < pageSizeItems.length; i++) {
			if (pageSizeItems[i].toLowerCase() === pageSizeParam) {
				$('span', $selected).text(pageSizeItems[i]);
			}
		}

		// year dropdown open/close handlers
		$selected.on('click', (e) => {
			e.stopPropagation();

			if(!$options.hasClass('show')){
				this.showOptions($options, $('i', $selected));
			}
			else {
				this.hideOptions($options, $('i', $selected));
			}
		});

		$('li a', $options).map((i,ele) => {
			let $this = $(ele),
				_self = this;

			$this.on('click', function(e) {
				e.preventDefault();
				e.stopPropagation();

				let value = _self.getYear($this);
				$('span', $selected).text(value);
				_self.hideOptions($options, $('i', $selected));

				let redirectUrl = $(this).attr('href');
				if(redirectUrl !== '' && redirectUrl) {
					window.location.href = redirectUrl;
				}
			});
		});
	}

	showOptions($list, $icon){
		$list.addClass('show');
		$icon.addClass('rotate');
	}

	hideOptions($list, $icon){
		$list.removeClass('show');
		$icon.removeClass('rotate');
	}

	getYear($item){
		let year = $item.text();
		return year;
	}

	qs(queryString) {
		let _self = this,
			query = window.location.search.substring(1),
			parms = query.split('&');

		for (var i=0; i<parms.length; i++) {
			var pos = parms[i].indexOf('=');
			if (pos > 0  && queryString == parms[i].substring(0,pos)) {
				return parms[i].substring(pos+1);
			}
		}
		return "";
	}
}
