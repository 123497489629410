'use strict';

import $ from 'jquery';

export default class PublicUpdatesSearch {
	constructor() {
		let $publicSearch = $('.public-updates-search'),
				$publicSearchSubmit = $('.public-updates-search__submit', $publicSearch),
				$publicSearchInput = $('.public-updates-search__input', $publicSearch);

		$publicSearchSubmit.on('click', function(e) {
			e.preventDefault();
			let searchValue = $publicSearchInput.val(),
				searchName= $publicSearchInput.attr("name"),
				searchURL = window.location.pathname;

			window.location.href = getQuery(searchValue, searchURL, searchName);
		});

		$publicSearchInput.on("keypress", function(e) {
			let keycode = e.keyCode ? e.keyCode : e.which;
			if (keycode == "13") {
				e.preventDefault();
				$(".public-updates-search__submit").trigger("click");
			}
		});

		function getQuery(searchValue, searchURL, searchName) {
			return searchURL + '?' + searchName + '=' + encodeURIComponent(searchValue);
		}

		if ((window.location.search).length) {
			$('html, body').stop().animate({
				scrollTop: ($('.public-updates').offset().top - 200)
			}, 700);
			return false;
		}
	}
}
