'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import 'jquery-match-height';

export default class HelpWidgetTabs {
	constructor() {
		let $helpTabs = $('.help-widget-tabs'),
			$menu = $('.help-widget-tabs__links', $helpTabs),
			$tabsScroll = $('.help-widget-tabs__scroll', $helpTabs),
			$item = $('li', $helpTabs),
			$itemHref = $('a', $item),
			$activeItem = $('li.active', $helpTabs),
			$link = $('a, button', $helpTabs),
			$toggle = $('.help-widget-tabs__toggle', $helpTabs),
			$toggleLabel = $('.label', $toggle);

		$item.matchHeight();
		$itemHref.matchHeight();

		$link.on('click.navTabLink', function(e) {
			let $this= $(this),
			label = $this.find('span').text(),
			$activeItem = $('li.active', $helpTabs);

			$activeItem.removeClass('active');
			$(this).parent().addClass('active');

			$toggleLabel.text(label);
			$menu.removeClass('active');
		});

		$toggle.on('click', function(e) {
			e.preventDefault();

			if ($menu.hasClass('active')) {
				$menu.removeClass('active');
			} else {
				$menu.addClass('active');
			}
		});

		let resizeTabs;

		$tabsScroll.matchHeight({
			target: $itemHref
		});

		$.fn.matchHeight._afterUpdate = function(event, groups) {
			// do something after all updates are applied
			let scrollHeight = $tabsScroll.outerHeight();

			$tabsScroll.css("height", (scrollHeight + 18) + "px");
		}

		resizeTabs = function() {
			$itemHref.matchHeight._update();
		}
		window.addEventListener('resize', resizeTabs);

		// enquire.register("screen and (max-width:1023px)", {
		// 	match() {
		// 		$tabsScroll.matchHeight({
		// 		    target: $itemHref
		// 		});

		// 		$.fn.matchHeight._afterUpdate = function(event, groups) {
		// 		    // do something after all updates are applied
		// 		    let scrollHeight = $tabsScroll.outerHeight();

		// 		    $tabsScroll.css("height", (scrollHeight + 18) + "px");
		// 		}

		// 		resizeTabs = function() {
		// 			$itemHref.matchHeight._update();
		// 		}
		// 		window.addEventListener('resize', resizeTabs);
		// 	},
		// 	unmatch() {
		// 		window.removeEventListener( "resize", resizeTabs);
		// 	}
		// });

		let resizeMobileTabsWidth;

		enquire.register("screen and (max-width:767px)", {
			match() {
				if ($('li', $menu).length === 2) {
					$menu.css('width', '430px');
				} else if ($('li', $menu).length === 3) {
					$menu.css('width', '610px');
				} else if ($('li', $menu).length === 4) {
					$menu.css('width', '770px');
				} else if ($('li', $menu).length === 5) {
					$menu.css('width', '940px');
				}

				resizeMobileTabsWidth = function() {
					if ($('li', $menu).length === 2) {
						$menu.css('width', '430px');
					} else if ($('li', $menu).length === 3) {
						$menu.css('width', '610px');
					} else if ($('li', $menu).length === 4) {
						$menu.css('width', '770px');
					} else if ($('li', $menu).length === 5) {
						$menu.css('width', '940px');
					}
				}

				window.addEventListener('resize', resizeMobileTabsWidth);
			},
			unmatch() {
				$menu.removeAttr("style");
				window.removeEventListener('resize', resizeMobileTabsWidth);
			}
		});

		let resizeTabletTabsWidth;

		enquire.register("screen and (min-width:768px) and (max-width:1023px)", {
			match() {
				if ($('li', $menu).length === 5) {
					$menu.css('width', '940px');
				}

				resizeTabletTabsWidth = function() {
					if ($('li', $menu).length === 5) {
						$menu.css('width', '940px');
					}
				}

				window.addEventListener('resize', resizeTabletTabsWidth);
			},
			unmatch() {
				$menu.removeAttr("style");
				window.removeEventListener('resize', resizeTabletTabsWidth);

			}
		});

		// Update toggle label
		$toggleLabel.text($activeItem.find('span').text());

		// Close toggle on clicking anywhere else
		$(document).on('click.navTabs', function(e) {
			if (!$(e.target).closest('.help-widget-tabs__toggle').length && !$(e.target).hasClass('nav-tabs__toggle')) {
				$menu.removeClass('active');
			}
		});
	}
}
