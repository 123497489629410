'use strict';

import $ from 'jquery';
import 'fancybox';

export default class VideoGalleryWidget {
	constructor() {
		$('[data-fancybox]').fancybox({
			arrows: true,
			loop: false,
			buttons: [
				'close'
			],
			iframe : {
				preload : false,
				css: {
					width: '800px',
					height: '600px'
				}
			}
		});
	}
}
