'use strict';

import $ from 'jquery';

export default class FaqSideNav {
  constructor() {
	let $faqSideNav = $('.faq-side-nav'),
		$sideNavList = $('ul', $faqSideNav);

	let sideNavPath = location.pathname,
		sideNavPathArray = sideNavPath.split('/');

	for (var i = sideNavPathArray.length-1; i >= 0; i--) {
		if(sideNavPathArray[i] === '') {
			sideNavPathArray.splice(i,1);
		}
	}

	$('a', $sideNavList).each(function (e) {
		let $this = $(this);

		for (let i = 0; i < sideNavPathArray.length; i++) {
			let currentHref = $this.attr('href'),
				lastIndex = currentHref.lastIndexOf("/"),
				splitString = currentHref.substring(lastIndex);

			currentHref = currentHref.substring(1, currentHref.length);

			let linkString = '/' + sideNavPathArray[1];
			
			if(splitString === linkString) {
				$this.parent().addClass('expanded');
			}
		}
	});
  }
}
