'use strict';

import $ from 'jquery';

export default class FaqSearch {
	constructor() {
		let $faqSearch = $('.faq-search'),
			$faqFilter = $('.faq-search__filter', $faqSearch),
			$faqInput = $('.faq-search-input', $faqSearch),
			$faqBtn = $('.faq-search-submit', $faqSearch),
			$faqValue = $('#faqSearch', $faqSearch);

		$('input', $faqFilter).on( "click", function(e) {
			$(this).prop("checked", true);
		});

		$faqInput.on("keypress", function(e) {
			let keycode = e.keyCode ? e.keyCode : e.which;
			if (keycode == "13") {
				e.preventDefault();
				$(".faq-search-submit").trigger("click");
			}
		});

		$faqBtn.on('click', function(e) {
			e.preventDefault();

			let searchValue = $faqValue.val(),
				searchURL = '/faq',
				searchFilter = $("input[name='faqSearch']:checked").val();

			if (searchFilter === "government") {
				window.open("http://www.ifaq.gov.sg/CCS/apps/fcd_faqmain.aspx?siteID=332&searchChoice=WOG&q=" + encodeURIComponent(searchValue));
			} else {
				window.location.href = getQuery(searchValue, searchURL);
			}
		});

		if ((window.location.search).length) {
			let sPageURL = decodeURIComponent(window.location.search.substring(1)),
				sURLVariables = sPageURL.split('&'),
				sParameterName,
				i;

			for (i = 0; i < sURLVariables.length; i++) {
				sParameterName = sURLVariables[i].split('=');

				if (sParameterName[0] === "q") {
					$('#faqSearch').val(sParameterName[1]);
				} else if (sParameterName[0] === "filter") {
					$('input[name="faqSearch"]').each(function(e) {
						let $value = $(this).val();

						if ($value === sParameterName[1]) {
							$(this).prop("checked", true);
						}
					});
				}
			}
		}

		function getQuery(searchValue, searchURL) {
			return searchURL + '?q=' + encodeURIComponent(searchValue);
		}
	}
}
