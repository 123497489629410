'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class SiteHeader {
	constructor() {
		let $nav = $('.nav'),
			$navMegamenu = $('.nav-megamenu', $nav),
			$header = $('.site-header'),
			$navListing = $('.nav__lvl1'),
			$window = $(window),
			$mobileMenuBtn = $('.js-mobile-menu'),
			$desktopSearch = $('.nav__search'),
			$notificationBar = $('.notification-bar'),
			$searchBar = $('.search-bar');

		let mastHeight = $('.site-header__masthead').outerHeight(true),
			topHeight = $('.site-header__top').outerHeight(true),
			notificationHeight = $('.notification-bar').outerHeight(true),
			resizeMegaHeight;

		function closeMobileMenu() {
			$mobileMenuBtn.removeClass('is-active');
			$('.m-search-bar').slideUp();
			$nav.slideUp();
		};

		enquire.register("screen and (max-width:1023px)", {
			match() {
				closeMobileMenu();
				$(".mainContent").animate({
				  'margin-top': (mastHeight + topHeight),
				}, 400);

				let $nav = $('.nav'),
				$menuLvl1 = $('.js-next-menu-lvl1', $nav),
				$menuLvl2 = $('.js-next-menu-lvl2', $nav),
				$mobileMenuBtn = $('.js-mobile-menu');

				$menuLvl1.on('click', function(e) {
					let $this = $(this),
						$children = $this.closest('li'),
						$navMegaMenu = $this.parent().siblings('.nav-megamenu');

					e.preventDefault();
					e.stopPropagation();

					if ($children.hasClass('is-shown')) {
						$navMegaMenu.stop().slideUp();
						$children.removeClass('is-shown');
					} else {
						$('.nav__lvl1 li').each(function() {
							$('.nav-megamenu', $(this)).stop().slideUp();
							$(this).removeClass('is-shown');
							$('.nav-megamenu .nav__lvl2 .nav-megamenu__listing', $(this)).stop().slideUp();
							$(this).find('.nav__lvl2 li').removeClass('is-shown');

							if ($(this).hasClass('is-shown')) {
								$(this).find('.nav-megamenu').stop().slideUp();
								$(this).removeClass('is-shown');
							}
						});

						$('.nav-megamenu__row').each(function() {
							if ($(this).hasClass('is-shown')) {
								$(this).find('.nav-megamenu__listing').stop().slideUp();
								$(this).removeClass('is-shown');
							}
						});

						$navMegaMenu.stop().slideDown();
						$children.addClass('is-shown');
					}
				});

				$menuLvl2.on('click', function(e) {
					let $this = $(this),
						$children = $this.parent().parent(),
						$navMegaMenu = $this.parent().siblings('.nav-megamenu__columns');

					e.preventDefault();
					e.stopPropagation();

					if ($children.hasClass('is-shown')) {
						$('.nav-megamenu__listing', $navMegaMenu).stop().slideUp();
						$children.removeClass('is-shown');
					} else {
						$('.nav__lvl2 > li').each(function() {
							if ($(this).hasClass('is-shown')) {
								$(this).find('.nav-megamenu__columns .nav-megamenu__listing').stop().slideUp();
								$(this).removeClass('is-shown');
							}
						});

						$('.nav-megamenu__listing', $navMegaMenu).stop().slideDown();
						$children.addClass('is-shown');
					}
				});

				$mobileMenuBtn.on('click', (e) => {
					e.preventDefault();

					if ($mobileMenuBtn.hasClass('is-active')) {
						$('.site-header__bottom').removeClass('clicked');
						closeMobileMenu();
						$('.mainContent, .site-footer').removeClass('bg-overlay');
					} else {
						$mobileMenuBtn.addClass('is-active');
						$('.mainContent, .site-footer').addClass('bg-overlay');
						$('.m-search-bar, .nav').slideDown();
						$('.site-header__bottom').addClass('clicked');
						// $nav.slideDown();
					}

					// Close menu by clicking anywhere of the document
					$(document).on('click.closeMobileMenu', function (e) {
						if (!$(e.target).closest('.nav').length && !$(e.target).closest('.js-mobile-menu').length && !$(e.target).closest('.site-search-input').length) {
							closeMobileMenu();
						}
					});
				});

				$window.on('load', function(e) {
					if ($(window).scrollTop() < 30) {
						$header.removeClass('is-sticky');
					}
				});

				$window.on('scroll.stickyHeader', function() {
					if ($(window).scrollTop() < 30) {
						$header.removeClass('is-sticky');
					} else {
						$header.addClass('is-sticky');
					}
				}).trigger('scroll.stickyHeader');
			},
			unmatch() {
				$('.js-back-menu').each(function() {
					$(this).parent().remove();
				});
				$('.nav').show();
				$('.js-next-menu, .js-back-menu, .js-mobile-menu, .js-search-menu, .lvl2-name').off('click');
				$('.mainContent, .site-footer').removeClass('bg-overlay');
			}
		});

		//desktop nav
		enquire.register("screen and (min-width:1024px)", {
			match() {
				let hoverTimeout;

				$('.lvl2-name', $navMegamenu).matchHeight();

				$window.on('load', function(e) {
					if ($(window).scrollTop() < 30) {
						$header.removeClass('is-sticky');
					}
				});

				$window.on('scroll.stickyHeader', function() {
					if ($(window).scrollTop() < 30) {
						$header.removeClass('is-sticky');
					} else {
						$header.addClass('is-sticky');
					}
				}).trigger('scroll.stickyHeader');

				//mouseover for mega menu
				function megamenuOpen($obj) {
					$obj.addClass('hovered');
				}

				function megamenuClose($obj) {
					$obj.removeClass('hovered');
				}

				$navListing.find('>li').on('mouseenter', function() {
					let $this = $(this);
					if ($this.find('.nav-megamenu').length) {
						hoverTimeout = setTimeout(function() {
							$('.mainContent, .site-footer').addClass('bg-overlay');
							megamenuOpen($this);
						}, 300);
					}

					let windowHeight = $(window).height(),
						recommendHeight = windowHeight - 140 - 60,
						navMegamenuHeight = $this.find('.nav-megamenu').height(),
						navMegamenuWrapHeight = $this.find('.nav-megamenu-wrap').height();

					if (navMegamenuHeight > recommendHeight) {
						$this.find('.nav-megamenu').css('height', recommendHeight);
					}

					resizeMegaHeight = function() {
						let windowHeight = $(window).height(),
							recommendHeight = windowHeight - 140 - 60,
							navMegamenuHeight = $this.find('.nav-megamenu').height();

						if (navMegamenuHeight > recommendHeight) {
						}
					}

					window.addEventListener('resize', resizeMegaHeight);

					function menuShowDefault(navlvl2) {
						setTimeout(function() {
							let $thisNavLvl = navlvl2;
							$thisNavLvl.addClass('clicked');
							$thisNavLvl.find('.nav-megamenu__columns').css("height", (navMegamenuWrapHeight + 10));
							$thisNavLvl.find('.nav-megamenu__columns').css('display', 'block');

							let $columnsHeight = $thisNavLvl.find('.nav-megamenu__columns').height(),
								$columnsFirstHeight = $thisNavLvl.find('.nav-megamenu__columns--first').height(),
								$navColumns = $thisNavLvl.find('.nav-megamenu__columns'),
								$columnsFirst = $thisNavLvl.find('.nav-megamenu__columns--first');

							if ($columnsFirstHeight > $columnsHeight) {
								$navColumns.append('<div class="nav-megamenu__columns--second"><ul class="nav-megamenu__listing"></ul></div>');

								let $lvl3Height = 10,
									$secondColList = "";

								$('.lvl3-list', $columnsFirst).each(function(e) {
									let $lvl3List = $(this),
										$lvl3ListHeight = $lvl3List.height();

									$lvl3Height += $lvl3ListHeight;

									if ($lvl3Height > $columnsHeight) {
										$secondColList += '<li class="lvl3-list">' + $lvl3List.html() + '</li>';
										$lvl3List.remove();
									}
								});

								$('.nav-megamenu__columns--second .nav-megamenu__listing').append($secondColList);
							}

							if ($thisNavLvl.find('.nav-megamenu__columns').length === 0) {
								$thisNavLvl.find('.lvl2-name').addClass('no-lvl2');
							}
						}, 100);
					}

					menuShowDefault($this.find('.nav__lvl2--list:first-child'));

					$('.lvl2-name', $this).on('mouseenter', function(e) {

						let $thisChild = $(this);

						$('.nav-megamenu', $this).each(function(e) {
							$(this).find('.nav__lvl2 li').removeClass('clicked');
							$(this).find('.nav-megamenu__columns').css('display', 'none');
						});

						if ($thisChild.parent().hasClass('clicked')) {
							$thisChild.parent().removeClass('clicked');
							$thisChild.parent().find('.nav-megamenu__columns').css('display', 'none');
						} else {
							$thisChild.parent().addClass('clicked');
							$thisChild.parent().find('.nav-megamenu__columns').css("height", (navMegamenuWrapHeight + 10));

							setTimeout(function(){
								let $columnsHeight = $thisChild.parent().find('.nav-megamenu__columns').height(),
									$columnsFirstHeight = $thisChild.parent().find('.nav-megamenu__columns--first').height(),
									$navColumns = $thisChild.parent().find('.nav-megamenu__columns'),
									$columnsFirst = $thisChild.parent().find('.nav-megamenu__columns--first');

								if ($columnsFirstHeight > $columnsHeight) {
									$navColumns.append('<div class="nav-megamenu__columns--second"><ul class="nav-megamenu__listing"></ul></div>');

									let $lvl3Height = 10,
										$secondColList = "";

									$('.lvl3-list', $columnsFirst).each(function(e) {
										let $lvl3List = $(this),
											$lvl3ListHeight = $lvl3List.height();

										$lvl3Height += $lvl3ListHeight;

										if ($lvl3Height > $columnsHeight) {
											$secondColList += '<li class="lvl3-list">' + $lvl3List.html() + '</li>';
											$lvl3List.remove();
										}
									});

									$('.nav-megamenu__columns--second .nav-megamenu__listing').append($secondColList);
								}
							}, 100);

							if ($thisChild.parent().find('.nav-megamenu__columns').length === 0) {
								$thisChild.addClass('no-lvl2');
							}

							$thisChild.parent().find('.nav-megamenu__columns').css('display', 'block');
						}
					});
				});

				$navListing.find('>li').on('mouseleave', function() {
					let $this = $(this);
					clearTimeout(hoverTimeout);
					hoverTimeout = setTimeout(function(){
						megamenuClose($this);
					}, 300);

					$('.nav-megamenu__columns').each(function(e) {
						$(this).parent().removeClass('clicked');
						$(this).css('display', 'none');
					});
				});

				$('.nav__lvl1').on('mouseleave', function() {
					$('.mainContent, .site-footer').removeClass('bg-overlay');
				});
			},
			unmatch() {
				$window.off('scroll.stickyHeader');
				$('.nav__link').off('mouseenter mouseout');
				$('.lvl2-name').off('mouseenter mouseout');
				// $notificationBar.slideUp(400);
				// $notificationBar.removeClass('noti-open');
				$searchBar.slideUp(400);
				$searchBar.removeClass('search-open');
				$navListing.find('>li').off('focusin focusout mouseenter mouseleave');
				$('.mainContent, .site-footer').removeClass('bg-overlay');
			}
		});

		let resizeNotiBar;

		resizeNotiBar = function() {
			$notificationBar.slideUp(400);
			$notificationBar.removeClass('noti-open');
		}

		window.addEventListener('resize', resizeNotiBar);

		// $('.nav__bell').on('click', e => {
		$('.nav__bell, .js-alert').on('click', e => {
			e.preventDefault();

			if ($searchBar.hasClass('search-open')) {
				$searchBar.slideUp(400);
				$searchBar.removeClass('search-open');
			}

			if ($notificationBar.hasClass('noti-open')) {
				$notificationBar.slideUp(400);
				$notificationBar.removeClass('noti-open');
				$( ".mainContent" ).animate({
					'margin-top': (mastHeight + topHeight),
				}, 400);
			} else {
				$notificationBar.slideDown(400);
				$notificationBar.addClass('noti-open');
				$( ".mainContent" ).animate({
					'margin-top': (mastHeight + topHeight + notificationHeight),
				}, 400);
			}
		});

		$desktopSearch.on('click', e => {
			e.preventDefault();

			if ($notificationBar.hasClass('noti-open')) {
				$notificationBar.slideUp(400);
				$notificationBar.removeClass('noti-open');
			}

			if ($searchBar.hasClass('search-open')) {
				$searchBar.slideUp(400);
				$searchBar.removeClass('search-open');
				$( ".mainContent" ).animate({
					'margin-top': (mastHeight + topHeight),
				}, 400);
			} else {
				$searchBar.slideDown(400);
				$searchBar.addClass('search-open');
				$( ".mainContent" ).animate({
					'margin-top': (mastHeight + topHeight + notificationHeight),
				}, 400);
			}
		});
	}
}
