'use strict';

import $ from 'jquery';

export default class BackToTop {
	constructor() {
		let $backToTop = $('.back-to-top');

		$backToTop.on('click', function(e) {
			e.preventDefault();
			$('html, body').animate({scrollTop: 0}, 600);
		});
	}
}
