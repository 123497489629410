'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import 'jquery-match-height';

export default class ResourcesListingItem {
	constructor() {
		let $resourcesListing = $('.resources-listing'),
			$resourcesListingItem = $('.resources-listing-item', $resourcesListing),
			$resourcesListingItemText = $('.resources-listing-item__text', $resourcesListingItem),
			num = $resourcesListingItem.length;

		let resizeListingHeight;

		enquire.register("screen and (min-width: 768px) and (max-width:1023px)", {
			match() {
				if ((num % 2) === 0) {
					$('.resources-listing-item:nth-last-child(2)', $resourcesListing).addClass('second-last-child');
				}

				$resourcesListingItemText.matchHeight();

				resizeListingHeight = function() {
					$resourcesListingItemText.matchHeight();
				}
		
				window.addEventListener('resize', resizeListingHeight);
			},
			unmatch() {
				$('.resources-listing-item:nth-last-child(2)', $resourcesListing).removeClass('second-last-child');
				window.removeEventListener('resize', resizeListingHeight);
				$resourcesListingItemText.matchHeight({
					remove: 'true'
				});
			}
		});


	}
}
