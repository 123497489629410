'use strict';

import $ from 'jquery';
import Emitter from 'tiny-emitter';
import objectFitImages from 'object-fit-images';
import 'jquery-match-height';
import jqueryscrollbar from 'jquery.scrollbar';

import TableResponsive from '../_modules/table-responsive/table-responsive';
import SideNav from '../_modules/molecules/side-nav/side-nav';
import BackToTop from '../_modules/atoms/back-to-top/back-to-top';

import OurPeopleItem from '../_modules/molecules/our-people-item/our-people-item';
import PublicationsItem from '../_modules/molecules/publications-item/publications-item';
import YearFilter from '../_modules/molecules/year-filter/year-filter';
import Accordion from '../_modules/molecules/accordion/accordion';
import FaqSearch from '../_modules/molecules/faq-search/faq-search';
import VideoGalleryItem from '../_modules/molecules/video-gallery-item/video-gallery-item';
import RelatedListingItem from '../_modules/molecules/related-listing-item/related-listing-item';
import ResourcesListingItem from '../_modules/molecules/resources-listing-item/resources-listing-item';
import ShowSortFilter from '../_modules/molecules/show-sort-filter/show-sort-filter';
import PublicUpdatesSearch from '../_modules/molecules/public-updates-search/public-updates-search';
import FaqSideNav from '../_modules/molecules/faq-side-nav/faq-side-nav';

import SiteHeader from '../_modules/organisms/site-header/site-header';
import MainNav from '../_modules/organisms/main-nav/main-nav';
import BannerCarousel from '../_modules/organisms/banner-carousel/banner-carousel';
import CaseRegistryWidget from '../_modules/organisms/case-registry-widget/case-registry-widget';
import HelpWidget from '../_modules/organisms/help-widget/help-widget';
import LatestResources from '../_modules/organisms/latest-resources/latest-resources';
// import ListingTableWidget from '../_modules/organisms/listing-table-widget/listing-table-widget';
// import PublicUpdatesWidget from '../_modules/organisms/public-updates-widget/public-updates-widget';
import CareersBanner from '../_modules/organisms/careers-banner/careers-banner';
import ResourcesBanner from '../_modules/organisms/resources-banner/resources-banner';
import RelatedPagesWidget from '../_modules/organisms/related-pages-widget/related-pages-widget';
import LandingWidget from '../_modules/organisms/landing-widget/landing-widget';
import VideoGalleryWidget from '../_modules/organisms/video-gallery-widget/video-gallery-widget';
import SocialSharingWidget from '../_modules/organisms/social-sharing-widget/social-sharing-widget';
import Faq from '../_modules/organisms/faq/faq';
import ListingSearch from '../_modules/organisms/listing-search/listing-search';


$(() => {
	//To emit events
	window.emitter = new Emitter();

	//Polyfill for object-fit
	objectFitImages();

	new SiteHeader();
	new MainNav();
	new BackToTop();

	let currentPath = location.pathname,
		pathArray = currentPath.split('/');

	for (var i = pathArray.length-1; i >= 0; i--) {
		if(pathArray[i] === '') {
			pathArray.splice(i,1);
		}
	}

	$('.nav__lvl1 li .nav__link').each(function (e) {
		let $this = $(this);

		for (let i = 0; i < pathArray.length; i++) {
			if($this.attr('href').indexOf(pathArray[i]) !== -1) {
				$this.addClass('active');
				return false;
			}
		}
	});


	if ($('table').length) {
		new TableResponsive();
	}

	if ($('.side-nav').length) {
		new SideNav();
	}

	if ($('.banner-carousel').length) {
		new BannerCarousel();
	}

	if ($('.case-registry').length) {
		new CaseRegistryWidget();
	}

	if ($('.help-widget').length) {
		new HelpWidget();
	}

	if ($('.latest-resources').length) {
		new LatestResources();
	}

	if ($('.our-people').length) {
		new OurPeopleItem();
	}

	if ($('.publications').length) {
		new PublicationsItem();
	}

	// if ($('.listing-table').length) {
	// 	new ListingTableWidget();
	// }

	if ($('.year-filter').length) {
		new YearFilter();
	}

	if ($('.accordion').length) {
		new Accordion();
	}

	if ($('.faq').length) {
		new FaqSearch();
	}

	if ($('.video-gallery').length) {
		new VideoGalleryItem();
	}

	if ($('.careers-banner').length) {
		new CareersBanner();
	}

	if ($('.resources-banner').length) {
		new ResourcesBanner();
	}

	if ($('.resources-listing').length) {
		new ShowSortFilter();
	}

	if ($('.related-listing').length) {
		new RelatedListingItem();
	}

	if ($('.resources-listing').length) {
		new ResourcesListingItem();
	}

	// if ($('.public-updates').length) {
	// 	new PublicUpdatesWidget();
	// }

	if ($('.related-pages').length) {
		new RelatedPagesWidget();
	}

	if ($('.video-gallery').length) {
		new VideoGalleryWidget();
	}

	if ($('.landing-widget').length) {
		new LandingWidget();
	}

	if ($('.faq-header').length) {
		new Faq();
	}

	if ($('.social-sharing-widget').length) {
		new SocialSharingWidget();
	}

	if ($('.public-updates-search').length) {
		new PublicUpdatesSearch();
	}

	if ($('.faq-side-nav').length) {
		new FaqSideNav();
	}

	if ($('.listing-search').length) {
		new ListingSearch();
	}

	let detectIE = function() {
		var ua = window.navigator.userAgent;

		// Test values; Uncomment to check result …

		// IE 10
		// ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

		// IE 11
		// ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

		// Edge 12 (Spartan)
		// ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

		// Edge 13
		// ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}

		var edge = ua.indexOf('Edge/');
		if (edge > 0) {
			// Edge (IE 12+) => return version number
			return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
		}

		// other browser
		return false;
	}

	var version = detectIE();

	if (version === false) {
		// document.getElementById('result').innerHTML = '<s>IE/Edge</s>';
	} else if (version >= 12) {
		// document.getElementById('result').innerHTML = 'Edge ' + version;
		$('html').addClass('is-ie is-edge');
	} else {
		// document.getElementById('result').innerHTML = 'IE ' + version;
		$('html').addClass('is-ie');

		if(version == 11) {
			$('html').addClass('ie-11');
		}
	}

	let	$searchWebsite = $('.search-website'),
		$select = $('select', $searchWebsite),
		$siteSearch = $('.site-search-input', $searchWebsite),
		$searchBtn = $(".site-search-submit", $searchWebsite);

	$select.map((i,ele) => {
		let $this = $(ele);

		let firstOption = $('option', $this).first().text(),
			firstValue = $('option', $this).first().val(),
			_clone = `<div class="dropdown-copy"><button type="button" value="${firstValue}">${firstOption}</button></div>`;

		$this.before(_clone);
	});

	let $copy = $('.dropdown-copy', $searchWebsite);

	$copy.map((i, ele) => {
		let $this = $(ele),
			$select = $this.next(),
			$button = $this.find('button');

		$select.on('click', (e) => {
			e.stopPropagation();

			if (!$this.hasClass('active')){
				$this.addClass('active');
			}
			else {
				$this.removeClass('active');
			}

			$('.dropdown-copy').not($this).removeClass('active');
		});

		$select.on('change', () => {
			let $option = $select.find(':selected');

			$select.prev().find('button').text($option.text());
			$select.prev().find('button').attr("value", $option.val());
		});

		$(window).on('click', () => {
			if ($this.hasClass('active')){
				$this.removeClass('active');
			}
		});
	});

	$siteSearch.on("keypress", function(e) {
		let keycode = e.keyCode ? e.keyCode : e.which;
		if (keycode == "13") {
			e.preventDefault();

			let $inputId;

			if ($(window).width() < 1024) {
				$inputId = $('#mobileSearch');
			} else {
				$inputId = $('#siteSearch');
			}

			let searchValue = $inputId.val(),
				searchName = $inputId.attr("name"),
				searchURL = '/search',
				domainName = "",
				$parentDiv = $inputId.parents().closest('.search-website'),
				$searchDiv;

			if ($parentDiv.hasClass('search-bar')) {
				$searchDiv = $('.search-bar');
			} else {
				$searchDiv = $('.m-search-bar');
			}

			if ($('.filter-select .dropdown-copy', $searchDiv).find("button").attr('value') === "related") {
				domainName = (" site:www.cccs.gov.sg");
			}

			if(!testSearchInput(searchValue)) {
				window.open(getQuery(searchValue, searchURL, searchName, domainName), '_blank');
			}
			else {
				$inputId.val('');
				$('.site-search--error-message').show();
			}
		}
	});

	// $searchBtn.on('click', function(e) {
	// 	e.preventDefault();
	// 	let $inputId;
	// 	if ($(window).width() < 1024) {
	// 		$inputId = $('#mobileSearch');
	// 	} else {
	// 		$inputId = $('#siteSearch');
	// 	}

	// 	let searchValue = $inputId.val(),
	// 		searchName = $inputId.attr("name"),
	// 		searchURL = '/search',
	// 		domainName = "",
	// 		$parentDiv = $inputId.parents().closest('.search-website'),
	// 		$searchDiv;

	// 	if ($parentDiv.hasClass('search-bar')) {
	// 		$searchDiv = $('.search-bar');
	// 	} else {
	// 		$searchDiv = $('.m-search-bar');
	// 	}

	// 	if ($('.filter-select .dropdown-copy', $searchDiv).find("button").attr('value') === "related") {
	// 		domainName = (" site:www.cccs.gov.sg");
	// 	}

	// 	window.open(getQuery(searchValue, searchURL, searchName, domainName), '_blank');
	// });

	function testSearchInput(searchValue) {
		const patt = new RegExp(/[!-+./:-@\[-`{-~]/);

		return patt.test(searchValue);
	}

	function getQuery(searchValue, searchURL, searchName, domainName) {
		return searchURL + '?' + searchName + '=' + encodeURIComponent(searchValue) + encodeURIComponent(domainName);
	}
});
