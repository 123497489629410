'use strict';

import $ from 'jquery';
import 'jquery-match-height';
import HelpWidgetTabs from '../../molecules/help-widget-tabs/help-widget-tabs';

export default class HelpWidget {
	constructor() {
		let $helpWidget = $('.help-widget'),
			$helpContent = $('.help-widget__content', $helpWidget),
			$helpItem = $('.help-widget__item', $helpWidget),
			$helpItemLink = $('a', $helpItem),
			$helpTabs = $('.help-widget-tabs', $helpWidget),
			$link = $('a', $helpTabs);

		let helpTabs = new HelpWidgetTabs();

		$link.map((i, ele) => {
			let $this = $(ele);
			$this.on('click.tabInfo', e => {
				e.preventDefault();

				let $parent = $this.parent(),
				$target = $this.attr('href'),
				$activeContent = $('.help-widget__content.active', $helpWidget);

				if (!$($target).hasClass('active')) {
					if ($activeContent.length) {
						// fade out old content before fading in new content
						$activeContent.removeClass('transit-in').removeClass('active');
						$($target).addClass('active');

						// fade in new content after old content faded out
						setTimeout(function() {
							$activeContent.stop().hide();
							$($target).stop().show(0, function() {
								$(this).addClass('transit-in');
							});
							$('.help-widget__item', $($target)).matchHeight();
						}, 200);

					} else {
						$($target).addClass('active');
						$($target).stop().show(0, function() {
							$(this).addClass('transit-in');
						});
						$('.help-widget__item', $($target)).matchHeight();
					}
				}
			});
		});

		$helpTabs.find('li:eq(0) a').trigger('click');
		$helpItem.matchHeight();
		// $helpItemLink.matchHeight();
	}
}
