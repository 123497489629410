'use strict';

import $ from 'jquery';

export default class Accordion {
	constructor() {
		let $accordion = $('.accordion');

		let $faq = $('.faq'),
			$faqHeader = $('.faq-header', $faq),
			$expand = $('.expand-all', $faqHeader),
			$collapse = $('.collapse-all', $faqHeader),
			$faqAccordion = $('.accordion', $faq);

		$accordion.map((i, ele) => {
			let $this = $(ele),
				$accordionController = $('.accordion__controller', $this),
				$accordionContent = $('.accordion__content', $this);

			$accordionController.on('click', () => {
				if(!$this.hasClass('open')){
					this.open($this);
					emitter.emit('openAccordion');
				}
				else {
					this.close($this);
				}

				let faqItemsExpanded = $('.faq .accordion.open').length,
					faqAccordionLength = $('.faq .accordion').length;

				if (faqItemsExpanded === faqAccordionLength) {
					$expand.hide();
					$collapse.show();
				} else if (faqItemsExpanded === 0) {
					$collapse.hide();
					$expand.show();
				}
			});
		});
	}

	close($object) {
		$object.find('.accordion__content').slideUp(300);
		$object.removeClass('open');
	}

	open($object) {
		$object.find('.accordion__content').slideDown(300);
		$object.addClass('open');
	}
}
