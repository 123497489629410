'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class Faq {
	constructor() {
		let $faq = $('.faq'),
			$faqHeader = $('.faq-header', $faq),
			$expand = $('.expand-all', $faqHeader),
			$collapse = $('.collapse-all', $faqHeader),
			$accordion = $('.accordion', $faq),
			$faqCategory = $('.faq-selector', $faq),
			$selected = $('.faq-selector__selected', $faqCategory),
			$options = $('.options', $faqCategory);

		$expand.on('click', function(e) {
			e.preventDefault();
			$(this).hide();
			$collapse.show();

			$accordion.map((i, ele) => {
				let $this = $(ele);

				$this.find('.accordion__content').slideDown(300);
				$this.addClass('open');
			});

		});

		$collapse.on('click', function(e) {
			e.preventDefault();
			$(this).hide();
			$expand.show();

			$accordion.map((i, ele) => {
				let $this = $(ele);

				$this.find('.accordion__content').slideUp(300);
				$this.removeClass('open');
			});
		});

		enquire.register("screen and (max-width:1023px)", {
			match() {
				function showOptions($list, $icon){
					$list.addClass('show');
					$icon.addClass('rotate');
				}

				function hideOptions($list, $icon){
					$list.removeClass('show');
					$icon.removeClass('rotate');
				}

				$selected.on('click', (e) => {
					e.stopPropagation();
					let _self = this;

					if(!$options.hasClass('show')){
						showOptions($options, $('i', $selected));
					}
					else {
						hideOptions($options, $('i', $selected));
					}
				});
			},
			unmatch() {
				$selected.off('click');
			}
		});
	}
}
