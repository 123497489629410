// previous version
'use strict';

import $ from 'jquery';

export default class SideNav {
	constructor() {
		let $sidenav = $('.side-nav'),
			$sidenavController = $('.sidenav-ctrl', $sidenav),
			$sidenavControllerLvl2 = $('.sidenav-ctrl__lvl2', $sidenav),
			$childrenList = $('.side-nav__lvl3', $sidenav),
			$expanded = $('.expanded', $sidenav);

		$sidenavController.on('click', function(){
			let $this = $(this),
				$parent = $this.parent(),
				$expanded = $('.expanded', $sidenav),
				$expandedChildren = $('.side-nav__lvl2', $expanded);

			//click on closed item
			if(!$this.parent().hasClass('expanded')){
				//close any expanded
				$expandedChildren.slideUp();
				$expanded.removeClass('expanded');
				//expanded selected item
				$parent.find('.side-nav__lvl2').slideDown(300);
				$parent.addClass('expanded');

				if ($parent.find('.side-nav__lvl2 .side-nav__lvl2--li').length === 1) {
					$parent.find('.side-nav__lvl2 .side-nav__lvl2--li').addClass('one-class');
				} else {
					let $lvl2Li = $parent.find('.side-nav__lvl2 .side-nav__lvl2--li');

					$lvl2Li.each(function(index, value) {
						let isLastElement = index == $lvl2Li.length - 1;

						if (isLastElement) {

							let $lvl3Li = $(this).find('.side-nav__lvl3 li');

							$lvl3Li.each(function(index, value) {
								let lvl3LastElement = index == $lvl3Li.length - 1;

								if (lvl3LastElement) {
									$(this).addClass('lvl3-last-element');
								}
							});
						}
					});
				}
			}

			//click on expanded item
			else {
				$parent.find('.side-nav__lvl2').slideUp(300);
				$parent.removeClass('expanded');
			}
		});

		$sidenavControllerLvl2.on('click', function() {
			let $this = $(this),
				$parent = $this.parent(),
				$expanded = $('.sub-expanded', $sidenav),
				$expandedChildren = $('.side-nav__lvl3', $expanded);

			//click on closed item
			if(!$this.parent().hasClass('sub-expanded')) {
				//close any expanded
				$expandedChildren.slideUp();
				$expanded.removeClass('sub-expanded');

				//expanded selected item
				$parent.find('.side-nav__lvl3').slideDown(300);
				// $parent.addClass('sub-expanded');
				if ($parent.find('.side-nav__lvl3').length) {
					$parent.addClass('sub-expanded');
				}
				let $lvl3Li = $parent.find('.side-nav__lvl3');

				$('li', $lvl3Li).each(function (index, value) {

					let lvl3LiHeight = $(this).find('p').height(),
						lvl2LiHeight = $(this).parent().parent().find('p').height();

					if (lvl3LiHeight === 36 && lvl2LiHeight === 36) {
						$(this).eq(index).attr("style","--top:-" + 51 + "%; --height:" + 136 + "%");
					} else if (lvl3LiHeight === 36 && lvl2LiHeight === 54) {
						$(this).eq(index).attr("style","--top:-" + 27 + "%");
					} else if (lvl3LiHeight === 36) {
						$(this).eq(index).attr("style","--top:-" + 36 + "%");
					} else if (lvl3LiHeight === 54 && lvl2LiHeight === 36) {
						$(this).eq(index).attr("style","--top:-" + 40 + "%");
					} else if (lvl3LiHeight === 54 && lvl2LiHeight === 54) {
						$(this).eq(index).attr("style","--top:-" + 51 + "%; --height:" + 136 + "%");
					} else if (lvl3LiHeight === 54) {
						$(this).eq(index).attr("style","--top:-" + 27 + "%");
					} else if (lvl3LiHeight === 18 && lvl2LiHeight === 36) {
						$(this).eq(index).attr("style","--top:-" + 80 + "%; --height:" + 136 + "%");
					} else if (lvl3LiHeight === 18 && lvl2LiHeight === 54) {
						$(this).eq(index).attr("style","--top:-" + 100 + "%; --height:" + 154 + "%");
					}
				});
			}

			//click on expanded item
			else{
				$parent.find('.side-nav__lvl3').slideUp(300);
				$parent.removeClass('sub-expanded');
			}
		});

		let sideNavPath = location.pathname,
			sideNavPathArray = sideNavPath.split('/');

		for (var i = sideNavPathArray.length-1; i >= 0; i--) {
			if(sideNavPathArray[i] === '') {
				sideNavPathArray.splice(i,1);
			}
		}

		$('.side-nav__lvl1 > li > a').each(function (e) {
			let $this = $(this);

			for (let i = 0; i < sideNavPathArray.length; i++) {
				let currentHref = $this.attr('href'),
					lastIndex = currentHref.lastIndexOf("/"),
					splitString = currentHref.substring(lastIndex);

				currentHref = currentHref.substring(1, currentHref.length);

				let linkString = '/' + sideNavPathArray[1];

				if(splitString === linkString) {
					$this.addClass('active');
					$this.parent().addClass('expanded');
					$this.parent().find('.side-nav__lvl2').slideDown(300);

					let $sideNavLvl2 = $this.parent().find('.side-nav__lvl2');

					if ($this.parent().find('.side-nav__lvl2 .side-nav__lvl2--li').length === 1) {
						$this.parent().find('.side-nav__lvl2 .side-nav__lvl2--li').addClass('one-class');
					}

					$('.side-nav__lvl2--li', $sideNavLvl2).each(function(index, value) {
						let $lvl2Li = $(this),
							lvl2Href = $lvl2Li.find('a').attr('href');

						// if ($lvl2Li.children('.side-nav__lvl3').length > 0) {
						// 	$lvl2Li.addClass('sub-expanded');
						// 	$this.parent().find('.side-nav__lvl3').slideDown(300);
						// }

						if(lvl2Href.indexOf(sideNavPathArray[2]) !== -1 && sideNavPathArray.length === 3) {
							$('> a', $lvl2Li).addClass('active');
							if ($lvl2Li.find('.side-nav__lvl3').length) {
								$lvl2Li.addClass('sub-expanded');
							}
							// $lvl2Li.addClass('sub-expanded');
							$lvl2Li.find('.side-nav__lvl3').slideDown(300);
							// $lvl2Li.parent().find('.side-nav__lvl3').slideDown(300);
						}

						if(lvl2Href.indexOf(sideNavPathArray[2]) !== -1 && sideNavPathArray.length === 4) {
							if ($lvl2Li.find('.side-nav__lvl3').length) {
								$lvl2Li.addClass('sub-expanded');
							}
							// $lvl2Li.addClass('sub-expanded');
							$lvl2Li.find('.side-nav__lvl3').slideDown(300);
						}

						$('.side-nav__lvl3 > li > a', $lvl2Li).each(function(index, value) {
							let $lvl3Li = $(this),
								lvl3Href = $lvl3Li.attr('href');

							if(lvl3Href.indexOf(sideNavPathArray[3]) !== -1) {
								$lvl3Li.addClass('active');
							}
						});

						let isLastElement = index == $('.side-nav__lvl2--li', $sideNavLvl2).length - 1;

						if (isLastElement) {

							let $lvl3Li = $(this).find('.side-nav__lvl3 li');

							$lvl3Li.each(function(index, value) {
								let lvl3LastElement = index == $lvl3Li.length - 1;

								if (lvl3LastElement) {
									$(this).addClass('lvl3-last-element');
								}
							});
						}
					});
				}
			}
		});
	}
}
