'use strict';

import $ from 'jquery';

export default class SocialSharingWidget {
	constructor() {
		let $socialSharing = $('.social-sharing-widget'),
			$shareEmail = $('.share-email', $socialSharing),
			$emailSubject = $shareEmail.data('subject'),
			$emailText = $shareEmail.data('text'),
			$emailLink = window.location.href;

		$shareEmail.on('click', function(e) {
			e.preventDefault();
			// window.location = 'mailto:?subject=' + $emailSubject + '&body=' +  $emailText + ' ' + $emailLink;
			window.location.href = 'mailto:?subject=' + $emailSubject + '&body=' +  $emailText + ' ' + $emailLink;
		});
	}
}
